import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Image,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import DroneKoala from "./components/DroneKoala";
import ITKoala from "./components/ITKoala";
import ProgressBar from "./components/ProgressBar";
import SocialMediaLinks from "./components/SocialMediaLinks";
import darkLogo from "./assets/dark-logo.png";
import peekingKoala from "./assets/peeking-koala.png"; // Import the peeking koala asset

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#27272A",
        color: "white",
      },
    },
  },
});

const betaCompletionPercentage = 86; // Set this dynamically from your backend later

const funnyDescriptions = [
  "Feeding The Koalas....We may have too many Quality Assurance Koalas.",
  "Koalas are taking a break...They’ll be back to coding soon!",
  "Just making sure the Koalas have enough snacks...Quality assurance is key!",
  "Work will continue after the dance battle has ended...it may take a number of weeks.",
  "Testing the coffee...Koalas need their caffeine fix!",
];

function App() {
  const [koalaPosition, setKoalaPosition] = useState({
    top: "10%",
    left: "10%",
    rotate: "0deg",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // State for animation
  const [uTilted, setUTilted] = useState(false); // State to track if "U" is tilted
  const [uDangling, setUDangling] = useState(false); // State to track if "U" is dangling
  const [uFellOff, setUFellOff] = useState(false); // State to track if "U" has fallen off
  const [showChatBubble, setShowChatBubble] = useState(false); // State for chat bubble visibility
  const [isRandomizing, setIsRandomizing] = useState(true); // Control whether koala should appear randomly
  const [description, setDescription] = useState(""); // State for the description

  useEffect(() => {
    const positions = [
      { top: "10%", left: "10%", rotate: "0deg" },
      { top: "10%", right: "10%", rotate: "45deg" },
      { bottom: "10%", left: "10%", rotate: "0deg" },
      { bottom: "10%", right: "10%", rotate: "60deg" },
      { top: "20%", left: "50%", rotate: "-120deg" },
      { top: "20%", right: "50%", rotate: "15deg" },
      { top: "50%", left: "25%", rotate: "0deg" },
      { top: "50%", right: "25%", rotate: "0deg" },
      { top: "70%", left: "50%", rotate: "15deg" },
      { bottom: "50%", left: "50%", rotate: "180deg" },
    ];

    const changePosition = () => {
      if (isRandomizing) {
        const randomPosition =
          positions[Math.floor(Math.random() * positions.length)];
        setKoalaPosition(randomPosition);
        setIsVisible(true);
        setUTilted(true);
        setIsAnimating(true);
        setTimeout(() => {
          setIsAnimating(false);
          setTimeout(() => setIsVisible(false), 1000);
        }, 3000);
      }
    };

    changePosition(); // Initial call to set a random position
    const intervalId = setInterval(changePosition, 10000); // Change position every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [isRandomizing]); // Re-run effect if isRandomizing changes

  useEffect(() => {
    // Set a random funny description when the component mounts
    const randomDescription =
      funnyDescriptions[Math.floor(Math.random() * funnyDescriptions.length)];
    setDescription(randomDescription);
  }, []); // Only run on mount

  const handleKoalaClick = () => {
    if (uTilted) {
      setUDangling(true);
      setTimeout(() => {
        setUFellOff(true);
        setUDangling(false);
        setIsRandomizing(false); // Stop random appearance
        setShowChatBubble(true); // Show chat bubble
        setTimeout(() => {
          setShowChatBubble(false); // Hide chat bubble after 4 seconds
          setIsRandomizing(true); // Resume random appearance
        }, 4000); // Show for 4 seconds
      }, 2000); // Keep dangling for 2 seconds
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <style>
        {`
          @keyframes dangling {
            0% { transform: translateY(0); }
            50% { transform: translateY(10px); }
            100% { transform: translateY(0); }
          }
          @keyframes fall {
            0% { transform: translateY(0); }
            100% { transform: translateY(100vh); }
          }
        `}
      </style>

      <Box
        position="relative"
        minH="100vh"
        bg="white"
        p="10px"
        borderRadius="8px"
        boxShadow="0 0 20px rgba(0, 0, 0, 0.2)"
      >
        <Box
          position="relative"
          minH="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="#27272A"
          borderRadius="8px"
        >
          <Flex
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            zIndex={1}
            p={4}
          >
            <Image
              src={darkLogo}
              alt="BearArmZ Logo"
              mb={2}
              style={{ marginTop: "-190px" }}
            />
            <Heading
              as="h1"
              size="2xl"
              mb={4}
              color="white"
              style={{ marginTop: "-200px" }}
              onClick={handleKoalaClick}
            >
              <span
                style={{
                  display: "inline-block",
                  transform: uTilted ? "rotate(-15deg)" : "rotate(0deg)",
                  transition: "transform 0.5s",
                  marginRight: "4px",
                  animation: uDangling ? "dangling 2s infinite" : "none",
                  opacity: uFellOff ? 0 : 1,
                  animation: uFellOff ? "fall 2s forwards" : "none",
                }}
              >
                U
              </span>
              nder Construction
            </Heading>
            <Text mb={4} maxWidth="600px">
              BearArmZ is currently in development, and we are dedicated to
              creating an innovative self-defense delivery app. We invite
              investors and developers to stay tuned for updates as we progress
              towards our beta launch.
            </Text>
            <ProgressBar
              progress={betaCompletionPercentage}
              description={description}
            />
            <SocialMediaLinks />
            <Text mt={6} maxWidth="600px">
              For inquiries, including partnership opportunities, please reach
              out to us at
              <Text ml={1} as="span" color="teal.200">
                will@beararmzapp.com
              </Text>
              .
            </Text>
          </Flex>
          {isVisible && (
            <Box
              position="absolute"
              top={koalaPosition.top}
              left={koalaPosition.left}
              style={{
                transform: `rotate(${koalaPosition.rotate}) translateY(${
                  isAnimating ? "0px" : "20px"
                })`,
                transition: "transform 3s ease, opacity 3s ease",
                opacity: isAnimating ? 1 : 0,
              }}
            >
              <Image
                src={peekingKoala}
                alt="Peeking Koala"
                onClick={handleKoalaClick}
                style={{ cursor: "pointer" }}
              />
            </Box>
          )}
          {showChatBubble && ( // Display the chat bubble when triggered
            <Box
              position="absolute"
              top={`calc(${koalaPosition.top} - 50px)`} // Position above the koala
              left={`calc(${koalaPosition.left} + 50px)`} // Adjust as needed to center
              transform="translate(-50%, -100%)" // Center the bubble above the koala
              bg="turquoise"
              borderRadius="8px"
              boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
              p={3}
              zIndex={2}
            >
              <Text color="black">Thanks, One More Thing to Fix.</Text>
            </Box>
          )}
          <Box position="absolute" top="10" left="10">
            <DroneKoala />
          </Box>
          <Box position="absolute" bottom="10" right="10">
            <ITKoala />
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
