import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import ITKoalaImage from '../assets/it-koala.png'; // Make sure to replace with actual image path

const ITKoala = () => {
  return (
    <Box>
      <Image src={ITKoalaImage} alt="IT Koala" />
    </Box>
  );
};

export default ITKoala;
