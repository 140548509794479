import React from 'react';
import { Box, Progress, Text } from '@chakra-ui/react';

const ProgressBar = ({ progress, description }) => {
  return (
    <Box width="400px" textAlign="center" mt={6}>
      <Text fontSize="lg" mb={2}>Beta Completion: {progress}%</Text>
      <Text fontSize="md" mb={2}>{description}</Text> {/* Display the description */}
      <Progress borderRadius="999px" value={progress} colorScheme="green" hasStripe isAnimated />
    </Box>
  );
};

export default ProgressBar;
