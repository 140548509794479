import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import DroneKoalaImage from '../assets/drone-koala.png'; // Replace with actual image path

const DroneKoala = () => {
  return (
    <Box boxSize="600px"  overflow="hidden">
      <Image src={DroneKoalaImage} alt="Drone Koala" objectFit="cover" width="100%" height="100%" />
    </Box>
  );
};

export default DroneKoala;
