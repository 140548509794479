import React from "react";
import { HStack, IconButton, Tooltip } from "@chakra-ui/react";
import { FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";

const SocialMediaLinks = () => {
  const socialMediaData = [
    {
      name: "LinkedIn",
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com/in/beararmz-technologies-409167307",
    },
    {
      name: "Instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com/beararmzapp",
    },
    {
      name: "Facebook",
      icon: <FaFacebook />,
      link: "https://www.facebook.com/profile.php?id=61558943168232",
    },
  ];

  return (
    <HStack spacing={4} mt={4}>
      {socialMediaData.map((social) => (
        <Tooltip key={social.name} label={social.name} hasArrow>
          <IconButton
            as="a"
            href={social.link}
            target="_blank"
            aria-label={social.name}
            icon={social.icon}
            colorScheme="teal"
            variant="outline"
            size="lg"
          />
        </Tooltip>
      ))}
    </HStack>
  );
};

export default SocialMediaLinks;
